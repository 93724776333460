import { CompanyItem } from '../features/company/types'

export default function getRandomCompanies(
  companies: CompanyItem[]
): CompanyItem[] {
  const randomCompanies = {}

  for (let i = 0; i < 6; ) {
    const index = Math.floor(Math.random() * (companies.length - 1))
    if (randomCompanies[index]) continue

    randomCompanies[index] = companies[index]
    i++
  }

  return Object.values(randomCompanies)
}
