import React, { FC } from 'react'
import styles from './ReviewsBlock.module.scss'
import SliderTop from '../../main/SliderTop'
import { CompanyItem } from '../../company/types'
import ReviewCard from '../../ReviewCard/ReviewCard'
import { IReviewCard } from '../../../interfaces/ReviewCard'
import CompaniesBlock from './CompaniesBlock'
import cn from 'classnames'

const ReviewsBlock: FC<{
  items: IReviewCard[]
  title: string
  companiesData?: CompanyItem[]
  showRating?: boolean
  showReactions?: boolean
  className?: string
}> = ({
  items,
  title,
  companiesData,
  showRating,
  showReactions,
  className,
}) => {
  return (
    <div className={className}>
      <SliderTop title={title} hideButtons />
      <ul
        className={cn(
          styles.list,
          companiesData && styles['list--hasCompanies']
        )}
      >
        {items.map((review, index) => (
          <li key={`${title}_${index}`} className={styles.item}>
            <ReviewCard
              key={review.id}
              {...review}
              rating={showRating && review.rating}
              showReactions={showReactions}
            />
          </li>
        ))}
        {companiesData && <CompaniesBlock items={companiesData} />}
      </ul>
    </div>
  )
}

export default ReviewsBlock
