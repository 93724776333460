import React, { FC } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import cn from 'classnames'
import Logo from '../../../public/icons/aside-logo.svg'
import { REVIEWS_LINK } from '../../../lib/constants/links'
import styles from './ReviewsBlock.module.scss'
import { CompanyItem } from '../../company/types'

const CompaniesBlock: FC<{ items: CompanyItem[] }> = ({ items }) => {
  return (
    <div className={styles.companiesContainer}>
      <div className={styles.companiesTop}>
        <Logo />
        <div className={styles.line} />
        <Link
          href={REVIEWS_LINK}
          className={cn('ls-03', styles.companiesTitle)}
        >
          Лента отзывов
        </Link>
      </div>
      <div>
        <p className={cn('ls-03', styles.companiesText)}>
          Более 100 работодателей
        </p>
        <div className={styles.companies}>
          {items.map(({ id, logo, slug, name }) => (
            <Link href={`/company/${slug}`} key={id} className={styles.company}>
              {logo ? (
                <Image
                  src={`${process.env.NEXT_PUBLIC_GRAPHQL_IMAGE}/${logo}`}
                  width="53"
                  height="53"
                  quality={75}
                  loader={({ src, width, quality }) =>
                    `${process.env.NEXT_PUBLIC_IMAGE_OPTIM}/rs:fill/w:${width}/q:${quality}/plain/${src}`
                  }
                  alt={name}
                />
              ) : (
                <span>{name.slice(0, 1).toUpperCase()}</span>
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CompaniesBlock
